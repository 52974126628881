<template>
<b-container id="mockTestMain" fluid class="p-0">
<div class="row">
    <b-overlay class="mt-6 fixedHeight" :show="showPreparation" rounded="sm" @hidden="onHidden">
    <div class="col-12 headerMock">
        <div class="row">
            <div class="col-9">
                <h3 class="text-left"> PTE Classes Mock Test</h3>
            </div>
            <div v-show="presentTime" class="col-3 text-center placeInline whiteColor">
                <!-- :minQuestion="minQuestion" -->
                <app-ParentTimer name="parentTimer" ref="parentTimer"  :increment="false"/>
                 <p :id="minQuestion" style="display:block!important" v-if="showQuestionNumber" class="ml-1">
                    <b-icon icon="justify-right" font-scale="1" ></b-icon>
                    {{minQuestion}} of {{maxQuestion}}</p>
            </div>
        </div>
    </div>
    <div id="instructions" class="col-12 p-0 m-0 text-center" v-if="mockSteps=='instructions'">
        <app-Instructions/>
    </div>
    <div id="headphoneTest" class="col-12 p-0 m-0 text-left" v-if="mockSteps=='headphoneTest'">
       <app-HeadPhone/>
    </div>
    <div id="microphoneTest" class="col-12 p-0 m-0 text-left" v-if="mockSteps=='microphoneTest'">
       <app-MicroPhone ref="MicroPhone"/>
    </div>
    <div id="keyBoardTest" class="col-12 p-0 m-0 text-left" v-if="mockSteps=='keyBoardTest'">
        <app-KeyBoardTest/>
    </div>
    <div id="testIntro" class="col-12 p-0 m-0 text-left" v-if="mockSteps=='testIntro'">
        <app-TestIntro/>
    </div>
    <div id="userIntro" class="col-12 p-0 m-0 text-left" v-if="mockSteps=='userIntro'">
        <app-UserIntro ref="userIntro"/>
    </div>
    <div id="questionStart" class="col-12 p-0 m-0 text-left" v-if="mockSteps=='questionStart'">
        <app-QuestionStartIntstructions :questionType="instructionType"/>
    </div>
    <div id="testStart" class="col-12 p-0 m-0 text-left"  v-if="mockSteps=='testStart'">
        <app-MockTestTemplate ref="mockTemplate" :questionList="allQuestions" :mockId="user_mock_id"/>
    </div>
     <div class="col-12  footerMock text-center">
        <b-button v-show='onQuestions' id="saveButton" class="pt-1 pb-1 pl-5 pr-5 mr-3" variant="danger"  @click="saveExit()">Save & Exit</b-button>
        <b-button v-show='!nextOff' id="nextButton" style="float:right" class="pt-1 pb-1 pl-5 pr-5 mr-3 " variant="info" @click="next()">Next</b-button>

    </div> 
    <template #overlay>
                <div class="text-center">
                <b-icon icon="stopwatch" font-scale="7" animation="cylon"></b-icon>
                <h4 class="mt-5" id="cancel-label">Please wait Mock Test will be ready in few moments......</h4>
                <b-button
                    class="mt-5"
                    ref="cancelButton"
                    variant="danger"
                    size="xl"
                    aria-describedby="cancel-label"
                    @click="showPreparation = false">
                    Cancel Processing
                </b-button>
                </div>
            </template>
            </b-overlay>
</div>
</b-container>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.13/vue.js"></script>
<script>
import $ from "jquery";
import { mapActions,mapGetters } from 'vuex'
import MockTestTemplate from '../components/mocktest/mocktemplate'
import Instructions from '../components/mockViews/instructions'
import HeadPhone from '../components/mockViews/headphoneTest'
import MicroPhone from '../components/mockViews/microphoneTest'
import KeyBoardTest from '../components/mockViews/keyBoardTest'
import TestIntro from '../components/mockViews/testIntro'
import UserIntro from '../components/mockViews/userIntro'
import QuestionStartIntstructions from '../components/mockViews/questionStartInstructions'
import ParentTimer from '../components/reuseable/timerplusmaxpresent'
import { ConsoleLoggingListener } from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.browser/ConsoleLoggingListener';
//for testing purpose
// import testing from '../components/reuseable/customAudioPlayerSlider'
export default {
    components: {
        'app-MockTestTemplate': MockTestTemplate,
         'app-Instructions':Instructions,
         'app-HeadPhone':HeadPhone,
        'app-MicroPhone':MicroPhone,
        'app-KeyBoardTest':KeyBoardTest,
        'app-TestIntro':TestIntro,
        'app-UserIntro':UserIntro,
        'app-QuestionStartIntstructions':QuestionStartIntstructions,
        'app-ParentTimer':ParentTimer
        // testing
    },
    data(){
        return{
            mockId:0,
            testReady:false,
            showPreparation: true,
            testPath:'@/assets/staticAudio/headphoneTest.mp3',
            mockSteps:'instructions',//'userIntro',//'instructions',
            allQuestions:null,
            user_id:null,
            user_mock_id:null,
            instructionType:'speaking-writing',
            nextOff:false,
            presentTime:false,
            minQuestion:1,
            showQuestionNumber:false,
            maxQuestion:0,
            completeQuestion:null,
            onQuestions:false,
            testResume:false,
            currentSavedMock:null,
            lastSavedMinutes:0,
            lastSavedSeconds:0,
            moduleWiseMockTest:false,
            selectedModule:'',
        }
    },
    created(){
        this.moduleWiseMockTest=false;
        if(this.$ls.get('user')==null){
            return this.$router.push('/')
        }
        else if(this.$ls.get('mockTestId')==null)
        {
            return this.$router.push('/')
        }
        else
        this.mockId= this.$ls.get('mockTestId')
        this.user_mock_id=this.$ls.get('user_mock_id')
        this.user_id=this.$ls.get('user').user_id
        //this.checkMockId()
        console.log('After mock Test ID check'+this.mockId)
        //console.log('User mock Test ID check'+this.user_mock_id)
        console.log("User Mock > ",this.user_mock_id)
        if(this.user_mock_id==null){
                this.updateUserMockTestStatus()
                this.testResume=false
            }
            else if(this.user_mock_id=='Speaking' | this.user_mock_id=='Reading' |this.user_mock_id=='Writing' |this.user_mock_id=='Listening' ){
                this.selectedModule=this.user_mock_id;
                console.log("Selected Module > ",this.selectedModule)
                this.user_mock_id=null;
                this.updateUserMockTestStatus();
                this.moduleWiseMockTest=true;
                this.testResume=false;
            }
            else{
                this.testResume=true
                //console.log(this.user_mock_id)
            }
         this.$nextTick(function(){
            this.fetchMockTest();
            //console.log($('#footer'))
            var footer=$('#footer')[0]
            //console.log(footer)
            //console.log($(footer))
            footer.remove()
            //document.getElementById("footer").style.display = null;
         })
         
    },
    methods:{
        ...mapActions({
            userMockSave:'usermocktest/saveMockForUser',
            questionservice: 'mocktest/fetchQuestion',
        }),
        ...mapGetters({
            getQuestionList: 'mocktest/questions',
            user_MockResponse:'usermocktest/UserMockResponse'
        }),
        disableButton(){
            this.nextOff=!this.nextOff
        },
        checkMockId(){
            //console.log(this.mockId)
            if(this.mockId==null)
            {
                 setTimeout(() => {  this.checkMockId() }, 3000);
            }
            else return
        },
        fetchMockTest(){
            var vm=this
            this.questionservice('mocktest/testId='+this.mockId).then(()=>{
            if(vm.getQuestionList().data !=null | vm.getQuestionList().data != undefined)
            {
                console.log("vm.allQuestions")
                vm.allQuestions = vm.getQuestionList().data
                console.log(vm.allQuestions)
                if(vm.user_mock_id!=null){
                    vm.$ls.set('mockTestResume','yes')
                    vm.fetchQuestionByMockId();
                }
                else{
                    vm.showPreparation= false
                }
            }
            })
        },
        async fetchQuestionByMockId(){
            var vm=this
            //console.log(this.user_mock_id)
            this.questionservice('usermocktest/userMockId='+this.user_mock_id).then(()=>{
                if(vm.getQuestionList().data !=null | vm.getQuestionList().data != undefined)
                { 
                    var lastMode='readAloud';

                    vm.completeQuestion = vm.getQuestionList().data
                    vm.fetchQuestionByMockParentId()
                    //console.log(vm.completeQuestion)
                    if(vm.completeQuestion.length>0){
                        vm.completeQuestion.forEach(comp => {
                            const mod=comp.question_module
                            
                            vm.allQuestions[mod]= vm.allQuestions[mod].filter((item)=>{
                                return item.question_id != comp.question_id
                            })
                        });
                    }
                    //console.log(vm.allQuestions)
                }
                vm.showPreparation= false
            })
        },
        async fetchQuestionByMockParentId(){
            var vm=this
            ////debugger
            //console.log(this.user_mock_id)
            this.questionservice('usermocktest/userMockParentId='+this.user_mock_id).then(()=>{
                if(vm.getQuestionList().data !=null | vm.getQuestionList().data != undefined)
                {
                    vm.currentSavedMock = vm.getQuestionList().data;
                    console.log(vm.currentSavedMock)
                    const savedTime=vm.currentSavedMock.saveTime;
                    vm.lastSavedMinutes=(savedTime!=null | savedTime!=undefined)?savedTime.split(":")[0]:0;
                    vm.lastSavedSeconds=(savedTime!=null | savedTime!=undefined)?savedTime.split(":")[1]:0;
                    console.log(vm.lastSavedMinutes);
                    console.log(vm.lastSavedSeconds);
                }
            })
        },
        saveExit(){
            this.$refs.mockTemplate.callinParentForReconfirm()
            this.$confirm(
                    "Are you sure to save and exit test",
                    "Alert",
                    "question",
                    {
                        confirmButtonText: "Save"
                    }
                    ).then(()=>{
                        //console.log('IS OK')
                            let routeData = this.$router.resolve({name: '/'});
                            window.open(routeData.href,"_self");
                    }).catch(() => {
                        //console.log("OK not selected.");
                    });
        },
        timeFromNumber(val){
            var minutes = Math.floor(val / 60); // 7
            var seconds = val % 60;
            minutes=(minutes<10)?'0'+minutes:minutes
            seconds=(seconds<10)?'0'+seconds:seconds
            return minutes+':'+seconds
        },
        onHidden() {
        // Focus the show button when the overlay is removed
        if(this.$refs.cancelButton !=null) this.$refs.cancelButton.focus()
        },
        next(){
            let timeMints=0;
            if(!navigator.onLine){
            return this.$alert(
              "Please check your network or switch to your mobile 4G or other WIFI networks. Click Retry to continue submitting",
              "Submission Failed",
              "warning",
              {
                  confirmButtonText: "Retry"
              }
              ).then(()=>{
                    this.next()
              })
            }
        if(this.mockSteps=='instructions'){
              this.mockSteps='headphoneTest'// for temp reason I change this code but need to uncomment
              //temp code
            //   const totalSeconds=2*60
            //   this.$nextTick(function(){
            //     this.presentTime = true
            //     this.$refs.parentTimer.setTime(totalSeconds)
            //     this.$refs.parentTimer.maxPresent=this.timeFromNumber(totalSeconds)
            //   })
            //   this.mockSteps='questionStart'

            //   Above is temp code
              
              
          }
          else if(this.mockSteps=='headphoneTest'){
              this.mockSteps='microphoneTest'
          }
          else if(this.mockSteps=='microphoneTest'){
              this.mockSteps='keyBoardTest'
          }
          else if(this.mockSteps=='keyBoardTest'){
              this.mockSteps='testIntro'
          }
          else if(this.mockSteps == 'testIntro'){
              //this.nextOff=true
              if(this.testResume){
                  this.mockSteps='questionStart'
              }
              else{
                debugger
                  this.mockSteps='userIntro';
                  console.log("Mock Step is > ", this.mockSteps);
                  if(this.selectedModule=="Speaking" | this.selectedModule=="") 
                  {
                    timeMints=55;
                }  
                
                  }
                this.$nextTick(function(){
                this.presentTime = true
                this.$refs.parentTimer.setTime(timeMints,false)
                this.$refs.parentTimer.maxPresent=this.timeFromNumber(timeMints)
                })
              
          }
          else if(this.mockSteps=='userIntro')
          {
            console.log("Mock Step is > ", this.mockSteps);
            this.$refs.userIntro.moveToNext()
              const totalSeconds=2*60
              this.$nextTick(function(){
                this.presentTime = true
                this.$refs.parentTimer.setTime(totalSeconds,false)
                this.$refs.parentTimer.maxPresent=this.timeFromNumber(totalSeconds)
              })
              if(this.selectedModule=="Listening" || this.selectedModule=="Reading" ){
                this.startQuestionModuleSelection();
                const totalSeconds=(this.selectedModule=="Reading")? 29*60:10*60;
                this.$nextTick(function(){
                    this.presentTime = true
                    this.$refs.parentTimer.setTime(totalSeconds,false)
                    this.$refs.parentTimer.maxPresent=this.timeFromNumber(totalSeconds)
                })
                this.mockSteps='testStart'
              }
              else this.mockSteps='questionStart'
          }
          else if(this.mockSteps=='questionStart')
          {
            console.log("Mock Step is 2 > ", this.mockSteps);
            console.log("Selected Module is > ", this.selectedModule);
            this.startQuestionModuleSelection();
              const totalSeconds=27*60
              this.$nextTick(function(){
                this.presentTime = true
                this.$refs.parentTimer.setTime(totalSeconds,false)
                this.$refs.parentTimer.maxPresent=this.timeFromNumber(totalSeconds)
              })
              this.mockSteps='testStart'
              
          }
          else if(this.mockSteps=='testStart'){
            //   ////debugger
            //   if(this.$ls.get('currentQuestionModule')!=null)
            //   {
                  this.$confirm('Are you sure if you want to submit this answer and go to the next question?',"Confirmation",
                              "question",
                              {
                                confirmButtonText: "Yes",
                                denyButtonText: "No",
                                showDenyButton:true
                              }
                              ).then((val)=>{
                                  //console.log(val)
                                  this.$refs.mockTemplate.moveToNextMK()
                                    this.$nextTick(()=>{
                                        this.updateQuestionMin()
                                    })
                                  }).catch(() => {
                                return //console.log('cancel')
                              });
            //   }
            //   else {
            //       this.$refs.mockTemplate.moveToNextMK()
            //             this.$nextTick(()=>{
            //                 this.updateQuestionMin()
            //             })
            //   }
          }
      },
      startQuestionModuleSelection(){
        this.$ls.set('endQuestionModule','');
        if(this.selectedModule=="Speaking") 
                  {
                    console.log("Selected Module is > ", this.selectedModule);
                    this.$ls.set('currentQuestionModule','readAloud');
                    this.$ls.set('endQuestionModule','summarized');
              
                }  
                else if(this.selectedModule=="Writing"){
                    console.log("Selected Module is > ", this.selectedModule);
                    this.$ls.set('currentQuestionModule','summarized');
                    this.$ls.set('endQuestionModule','mcqSingle');
                }
                else if(this.selectedModule=="Reading"){
                    console.log("Selected Module is > ", this.selectedModule);
                    this.$ls.set('currentQuestionModule','mcqSingle');
                    this.$ls.set('endQuestionModule','summarizedSpoken');
                }
                
                else if(this.selectedModule=="Listening"){
                    console.log("Selected Module is > ", this.selectedModule);
                    this.$ls.set('currentQuestionModule','summarizedSpoken');
                    this.$ls.set('endQuestionModule','speakingEndModule');
                }
                else{
                    console.log("Selected Module is > ", this.selectedModule);
                        this.$ls.set('currentQuestionModule','readAloud');
                        this.onQuestions=true
                    }
                  
      },
      timerEnd(){
          $('#nextButton').click()
      },
      createGuid()  
        {  
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {  
                var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);  
                return v.toString(16);  
            });  
        },
      async checkUserMockStatus(){
            if(this.user_mock_id==null){
                this.updateUserMockTestStatus()
                this.testResume=false
            }
            else{
                this.testResume=true
                //console.log(this.user_mock_id)
            }
    },
        updateUserMockTestStatus(){
           const data={
                    url:'usermocktest/updateusermocktestStatus',
                    //user_mock_id:this.user_mock_id,
                    mock_status:'InComplete',
                    user_id:this.user_id,
                    mock_test_id:this.mockId
                    }
            this.userMockSave(data).then(()=>{
                const response=this.user_MockResponse()
                this.saveUserMockTest()
                //console.log(response)
            })
        },
        updateUserMockTestQuestionId(lastId, lastModule){
           const data={
                    url:'usermocktest/updateUserMockLastQuestionById',
                    user_mock_id:this.user_mock_id,
                    last_finish_question_id:lastId,
                    last_module:lastModule,
                    last_quest_number:this.minQuestion,
                    saveTime:this.$refs.parentTimer.timeLeft
                    }
                    //console.log(data)
            this.userMockSave(data).then(()=>{
                const response=this.user_MockResponse()
                //console.log(response)
            })
        },
        saveUserMockTest(){
        this.user_mock_id=this.createGuid()
        this.$ls.set('user_mock_id',this.user_mock_id)
           const data={
                    url:'usermocktest/createusermocktest',
                    user_mock_id:this.user_mock_id,
                    mock_test_id:this.mockId,
                    user_id:this.user_id
                    }
            this.userMockSave(data).then(()=>{
                const response=this.user_MockResponse()
                //console.log(response)

            })
        },
      changeTimeFromChild(totalminutes,seconds){
          ////debugger
          let totalSeconds=totalminutes*60;
          totalSeconds=totalSeconds+seconds;
          this.$nextTick(function(){
                this.presentTime = true
                // if(this.currentSavedMock!=null){
                //     if(this.currentSavedMock.saveTime!=null){
                //         var timearray=this.currentSavedMock.saveTime.split(":");
                //         const currentMinute=parseInt(timearray[0])*60
                //         if(isNaN(currentMinute)) this.$refs.parentTimer.setTime(totalSeconds,false)
                //         const currentsecond=parseInt(timearray[1])
                //         if(isNaN(currentsecond)) this.$refs.parentTimer.setTime(totalSeconds,false)
                //         const currentTotalSeconds=currentMinute+currentsecond
                //         this.$refs.parentTimer.setTime(currentTotalSeconds,false)
                //     }
                //     else this.$refs.parentTimer.setTime(totalSeconds,false)
                // }
                //else this.$refs.parentTimer.setTime(totalSeconds,false)
                this.$refs.parentTimer.setTime(totalSeconds,false)
                //this.currentSavedMock=null
                this.$refs.parentTimer.maxPresent=this.timeFromNumber(totalSeconds)
             })
      },
      changeQuestionNumber(show,min,max){
          this.showQuestionNumber=show
          this.maxQuestion = max
          this.minQuestion = min
          if(this.minQuestion==0) this.minQuestion=1
      },
      updateQuestionMin(){
          if(this.maxQuestion> this.minQuestion){
              this.minQuestion=this.minQuestion+1
          }
          if(this.minQuestion==0) this.minQuestion=1
      }
    },
}
</script>
<style scoped>
#footer
{
    display: none!important;
}

</style>
<style>
body{
    margin-bottom: 50px!important;
}
.headerMock h3{
color: #fff;
}
.footerMock, .headerMock
{
background:#607090; 
padding:10px 20px!important
}
.footerMock
{
padding-right:50px!important;
position: fixed!important;
bottom: 0px;
}
#headphoneTest, #microphoneTest, #keyBoardTest, #testIntro, #userIntro, #questionStart,#testStart
{
    width: 100vw;
    height: 105%;
}
ol.number{
  list-style-type: decimal;
}
ul.square {
  list-style-type: square;
}
.audio-container
{
    height: 80px!important;
}
.microRecordDiv
{
background: #eaf1fb;

    padding-top: 10px;
    border: 1px solid #6c757d!important;
}
div.invissible
{
opacity: 0!important;
position: absolute!important;
}

.microRecordDiv p
{
    font-size: .8rem!important;
    color: #27292b;
}
.btn-secondary.recordingBtn, .btn-secondary.recordingBtn.disabled
{
    color: #000;
    background-color: #8fd9ee;
    border-color: #6c757d;
    font-weight: 600;
    padding: 2px 7px;
    border-radius: 0px;
    margin-left: 10px;
    /* font-size: .8rem; */
    font-size: 1rem;
    margin-top: 10px;
}
.placeInline p, .placeInline div{
display: inline!important;
}
.whiteColor p, .whiteColor div, .whiteColor span
{
    color: #fff!important;
}
.blackColor
{
    color: #000!important;
}
#nextButton
{
    border-radius: 0px!important;
}
#nextButton:hover {
      background-color: #0a5c69;
    /* border: 15px; */
    font-weight: 500;
    border-color: #0a5c69;
}
#nextButton.disabled{
        cursor: not-allowed!important;
}
#mockTestMain div.fixedHeight{
    overflow: hidden!important;
}
</style>